import { captureException } from "@sentry/browser";
import { AxiosError } from "axios";

/**
 * Interceptor function that logs errors and captures exceptions using Sentry.
 * @param error - The AxiosError object representing the error.
 * @returns A rejected Promise with the error object.
 */
export const loggingInterceptor = (error: AxiosError) => {
  /* Log 4xx errors, as they are likely to be client issues that are actionable.
  4xx errors are expected in some cases. Use Axios validateStatus option to ignore on individual requests */
  if (error.response?.status && error.response.status >= 400 && error.response.status < 500) {
    const ignoredStatuses = [
      404, // Not Found: expected when we simply don’t have some entity/data
      401, // Unauthorized: expected when user is not authenticated
      403, // Forbidden: expected when user is authenticated but not authorized
      409, // Conflict: expected when user tries to create a resource that already exists
    ];
    if (!ignoredStatuses.includes(error.response.status)) {
      captureException(error); // Capture the error in Sentry
    }
  }

  /* Do not log network errors, as they are likely to be client issues that are not actionable. */
  /* Do not log 5xx errors, as they are likely to be server issues, and will be logged by the server. */
  return Promise.reject(error);
};
