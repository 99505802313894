import { TriggerVote, TriggerVoteCreateResponse } from "../../types/Triggers";
import { blastApi } from "../apiClients/blastApi";

export class TriggersApiClient {
  public constructor() {}
  public getTriggerPollVote = async (id: string): Promise<TriggerVote> => {
    const { data } = await blastApi.get(`/v1/triggers/polls/${id}/vote`);
    return data;
  };

  public postTriggerPollVote = async (id: string, optionId: string): Promise<TriggerVoteCreateResponse> => {
    const { data } = await blastApi.post(`/v1/triggers/polls/${id}/vote`, {
      optionId,
    });
    return data;
  };
}
