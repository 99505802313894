import { PickemsUserResult, PostUserPicksPayload, PostUserPicksResponse } from "./types";
import { PostPlayerPicksPayload, UserPlayerPicks, UserPlayerPicksSchema } from "./schemas";
import { blastApi } from "../apiClients/blastApi";

export class PickemsApiClient {
  public constructor() {}

  public postUserPicksInBulk = async ({ pickemsId, picks }: PostUserPicksPayload): Promise<PostUserPicksResponse> => {
    const { data } = await blastApi.post(`/v1/pickems/${pickemsId}/my-picks`, {
      picks,
    });
    return data;
  };

  public getUserPicks = async ({ pickemsId }: { pickemsId: string }): Promise<PickemsUserResult[]> => {
    const { data } = await blastApi.get<PickemsUserResult[]>(`/v1/pickems/${pickemsId}/my-picks`);
    return data?.map((pick) => {
      return {
        ...pick,
        choiceId: decodeURIComponent(pick.choiceId),
      };
    });
  };

  postPlayerPicks = async (pickemsId: string, payload: PostPlayerPicksPayload): Promise<void> => {
    await blastApi.post(`/v1/player-pickems/${pickemsId}/my-picks`, payload);
  };

  public getUserPlayerPicks = async ({ pickemsId }: { pickemsId: string }): Promise<UserPlayerPicks[]> => {
    const { data } = await blastApi.get(`/v1/player-pickems/${pickemsId}/my-picks`);
    return UserPlayerPicksSchema.array().parse(data);
  };
}
