import { useQuery } from "@tanstack/react-query";
import { usePointsApi } from "../../../api/ApiProvider/hooks";
import { useUserProfile } from "../../../auth/useUserProfile";

const useUserWallet = () => {
  const userProfile = useUserProfile();
  const pointsApi = usePointsApi();

  return useQuery({
    queryKey: ["userWallet", userProfile?.id],
    queryFn: userProfile ? () => pointsApi.getUserWallet(userProfile.id) : undefined,
    enabled: !!userProfile,
    staleTime: 30_000,
  });
};

export { useUserWallet };
