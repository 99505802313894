import {
  LeaderboardRankingResponse,
  LeaderboardResponseSchema,
  Tournament,
  TournamentSchema,
  RankHistories,
  RankHistoriesSchema,
} from "./types";
import { blastDataBucketApi } from "../apiClients/blastDataBucketApi";

export class LeaderboardDataBucketApiClient {
  public constructor() {}

  public getLeaderboardRankings = async (date: string): Promise<LeaderboardRankingResponse> => {
    const { data } = await blastDataBucketApi.get(`/valve-standings/${date}.json`);
    return LeaderboardResponseSchema.parse(data);
  };

  public getRankingHistories = async (): Promise<RankHistories> => {
    const { data } = await blastDataBucketApi.get(`/valve-standings/histories.json`);
    return RankHistoriesSchema.parse(data);
  };

  public getUpcomingTournaments = async (): Promise<Tournament[]> => {
    const { data } = await blastDataBucketApi.get(`/leaderboard/tournaments.json`);
    const currentDate = new Date();
    data.filter((tournament: Tournament) => {
      const startDate = new Date(tournament.startDate);
      return startDate > currentDate;
    });
    return TournamentSchema.array().parse(data);
  };

  public getPastTournaments = async (): Promise<Tournament[]> => {
    const { data } = await blastDataBucketApi.get(`/leaderboard/past_tournaments.json`);
    return TournamentSchema.array().parse(data);
  };
}
