import { useAvatarModal } from "@providers/AvatarModal/useAvatarModal";
import { useAuth } from "../../../../../../../auth/AuthProvider/hooks/useAuth";
import bgPng from "./assets/background.png";
import classNames from "classnames";
import { Link } from "react-router";

export const ProfilePopup = ({
  isOpen,
  avatarSrc,
  username,
  className,
  onClose,
}: {
  isOpen: boolean;
  avatarSrc?: string;
  username?: string;
  className?: string;
  onClose: () => void;
}) => {
  const { logout } = useAuth();
  const { setIsOpen: setIsAvatarModalOpen } = useAvatarModal();

  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{ "--image-url": `url(${bgPng})` } as React.CSSProperties}
      className={classNames(
        `absolute right-0 top-12 z-navbar flex w-80 flex-col items-center bg-[image:var(--image-url)] bg-cover bg-center`,
        className,
      )}
    >
      <img alt="background" src={avatarSrc} className="m-4 size-20 rounded-full bg-white/10" />
      <div className="!normal-case font-style-desktop-h3">{username ?? "?"}</div>
      <div className="mt-4 w-full bg-canvas-90 px-3">
        <Link
          to="/profile"
          onClick={onClose}
          className="block w-full py-3 text-left transition-all duration-200 ease-in-out font-style-label-3 hover:text-neutral-10"
        >
          Go to Profile
        </Link>
        <div className="mx-auto h-px bg-canvas-80" />
        <button
          onClick={() => setIsAvatarModalOpen(true)}
          className="block w-full py-3 text-left transition-all duration-200 ease-in-out font-style-label-3 hover:text-neutral-10"
        >
          Change avatar
        </button>
        <div className="mx-auto h-px bg-canvas-80" />
        <button
          onClick={() => void logout()}
          className="block w-full py-3 text-left transition-all duration-200 ease-in-out font-style-label-3 hover:text-neutral-10"
        >
          Sign out
        </button>
      </div>
    </div>
  );
};
