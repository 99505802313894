import { captureException } from "@sentry/browser";
import { PatchUserProfileRequest, PostUserProfileRequest, UserProfile, ValidateUsernameResponse } from "./types";
import * as schemas from "./schemas";
import { blastApi } from "../apiClients/blastApi";

export class UsersApiClient {
  constructor() {}

  public getUser = async (userId: string): Promise<UserProfile | null> => {
    const { data, status } = await blastApi.get(`/v1/users/${userId}/profile`, {
      validateStatus: (status) => {
        return status === 404 || status === 200;
      },
    });
    if (status === 404) return null;

    return schemas.userProfile.parse(data);
  };

  public patchUser = async (userId: string, request: PatchUserProfileRequest): Promise<void> => {
    try {
      const parsedRequest = schemas.patchUserProfileRequest.parse(request);
      await blastApi.patch(`/v1/users/${userId}/profile`, parsedRequest);
    } catch (error) {
      captureException(`Error patching user with userId ${userId}: ${error}`);
      throw new Error("Failed to patch user.");
    }
  };

  public postUser = async (request: PostUserProfileRequest): Promise<void> => {
    try {
      const parsedRequest = schemas.postUserProfileRequest.parse(request);
      await blastApi.post("/v1/users/profile", parsedRequest);
    } catch (error) {
      captureException(`Error posting user with userId ${request?.id}: ${error}`);
      throw new Error("Failed to create user.");
    }
  };

  public validateUsername = async (username: string): Promise<ValidateUsernameResponse> => {
    try {
      const response = await blastApi.get(`/v1/users/validate?username=${encodeURI(username)}`);
      const parsedResponse = schemas.validateUsernameResponse.parse(response.data);
      return parsedResponse;
    } catch (error) {
      captureException(`Error validating username: ${error}`);
      throw new Error("Failed to validate username.");
    }
  };

  public claimAvatar = async ({ userId, avatarId }: { userId: string; avatarId: string }): Promise<void> => {
    try {
      await blastApi.post(`/v1/users/${userId}/profile/avatars/${avatarId}`);
    } catch (error) {
      captureException(`Error claiming avatar with id ${avatarId}: ${error}`);
      throw new Error(`Failed to claim avatar with id: ${avatarId}.`);
    }
  };

  public claimEmoji = async ({ userId, emojiId }: { userId: string; emojiId: string }): Promise<void> => {
    try {
      await blastApi.post(`/v1/users/${userId}/profile/emojis/${emojiId}`);
    } catch (error) {
      captureException(`Error claiming emoji with id ${emojiId}: ${error}`);
      throw new Error(`Failed to claim emoji with id: ${emojiId}.`);
    }
  };

  public acceptChatRules = async () => {
    await blastApi.patch(`/v1/users/profile/accept-chat-rules`);
  };

  getSteamConnectionLink = async ({ redirectPath }: { redirectPath: string }) => {
    const { data } = await blastApi.get(`/v1/users/connections/steam/link?redirectPath=${redirectPath}`);
    return schemas.GetSteamConnectionLinkResponseSchema.parse(data);
  };
}
