import { z } from "zod";
import { TournamentSchema } from "./Tournament";
import { TeamSchema } from "./Team";
import { MapSchema } from "./Map";

export const DotaMatchMetadataSchema = z.object({
  _t: z.literal("dota_match"),
  externalStreamUrl: z.string().nullish(),
});

export const BountyMatchMetadataSchema = z.object({
  _t: z.literal("bounty_match"),
  wager: z.number().nullish(),
});

export const DbMatchMetadataSchema = z.discriminatedUnion("_t", [DotaMatchMetadataSchema, BountyMatchMetadataSchema]);

export type DbMatchMetadata = z.infer<typeof DbMatchMetadataSchema>;

const NextSeriesSchema = z.object({
  tournamentUUID: z.string(),
  seriesUUID: z.string(),
  bracketPosition: z.enum(["POSITION_A", "POSITION_B"]),
});

export const SeriesSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  timeOfSeries: z.coerce.date(),
  type: z.string(),
  index: z.number(),
  tournament: TournamentSchema,
  metadata: DbMatchMetadataSchema.nullish(),
  teamA: TeamSchema.nullish().transform((team) => team ?? null),
  teamB: TeamSchema.nullish().transform((team) => team ?? null),
  teamAScore: z.number(),
  teamBScore: z.number(),
  maps: z.array(MapSchema).nullable().optional(),
});

export const SeriesByTournamentSchema = SeriesSchema.extend({
  winnerGoesTo: NextSeriesSchema.nullable().optional(),
  loserGoesTo: NextSeriesSchema.nullable().optional(),
});

export type Series = z.infer<typeof SeriesSchema>;
