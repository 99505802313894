import { useEffect } from "react";
import { useSearchParams } from "react-router";
import { useAuthModal } from "../../../auth/AuthModalProvider";

export function useSignUpLoginErrorCheck() {
  const [searchParams] = useSearchParams();

  const authModal = useAuthModal();

  useEffect(() => {
    if (searchParams.has("sign-up-error")) {
      authModal?.openModal(
        ["email-already-exists", "user-cancelled-authorize"].includes(searchParams.get("sign-up-error") || "")
          ? "login"
          : "register",
      );
    }
  }, [searchParams, authModal]);
}
