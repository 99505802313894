const production = [
  "/article*",
  "/cs/news",
  "/cs/news/*",
  "/dota/news",
  "/dota/news/*",
  "/dota/heroes",
  "/dota/heroes/*",
  "/rl/news*",
  "/quiz/*",
  "/gaming*",
  "/cs-quiz*",
  "/cs/bounty-simulator*",
];

export const ssrRoutesPatterns = {
  production,
  development: [...production],
  local: [...production],
};
