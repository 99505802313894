/* eslint-disable react-refresh/only-export-components */
import cookies from "js-cookie";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useAuthApi, useDdsClient } from "../../api/ApiProvider/hooks";
import { LoginRequest, RequestResetPasswordRequest, SignupRequest } from "../../api/auth/types";
import { useDDSEvents } from "../../shared/hooks/useDDSEvents";
import { rudderstack } from "../../rudderstack";
import { useAuthModal } from "../AuthModalProvider";
import { getDomain } from "../authInterceptor";
import { AuthService } from "./services/AuthService";

export const REGISTRATION_EMAIL_COOKIE = "blasttv-registration-email";

export type LoginResult = "success" | "wrong-credentials" | "not-verified";

export type RegisterResult = "success" | "email-already-registered" | "invalid-password" | "invalid-email";

export interface AuthProviderValue {
  authService: AuthService;
  isAuthenticated: boolean;
  hasAccessToken: boolean;
  login: (credentials: LoginRequest) => Promise<LoginResult>;
  logout: () => Promise<void>;
  register: (credentials: SignupRequest) => Promise<RegisterResult>;
  resetPasswordRequest: (payload: RequestResetPasswordRequest) => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
  userId?: string;
  userEmail?: string;
}

export const AuthContext = createContext<AuthProviderValue | null>(null);

function AuthProvider({ children }: { children?: React.ReactNode }) {
  const authApi = useAuthApi();
  const ddsClient = useDdsClient();
  const authModal = useAuthModal();

  const authService = useMemo(() => new AuthService(authApi), [authApi]);

  const [userId, setUserId] = useState<string | undefined>(authService.userId);
  const [userEmail, setUserEmail] = useState<string | undefined>(authService.email);

  useEffect(() => {
    if (!userId) {
      return;
    }

    rudderstack?.identify(userId);
  }, [userId]);

  useEffect(() => {
    const initTime = Date.now();

    return () => {
      // skip reconnection if state changed too fast
      // Most probably it's initial multiple re renderings
      if (Date.now() - initTime <= 200) {
        return;
      }

      // reconnect when user auth state changed
      ddsClient?.reconnect();
    };
  }, [ddsClient, userId]);

  useEffect(() => {
    setUserEmail(authService.email);
  }, [userId, authService]);

  const onBan = useCallback(() => {
    authApi.logout().then(() => setUserId(undefined));
    authModal?.openModal("ban");
  }, [authApi, authModal]);

  useDDSEvents({
    banListener: onBan,
    disabled: !userId,
  });

  useEffect(() => {
    if (authService.hasAccessToken) {
      setUserId(authService.userId);
    } else if (authService.shouldUpdateAccessToken) {
      authService.updateSession().then(() => {
        setUserId(authService.userId);
      });
    }
  }, [authService]);

  const providerValue = useMemo<AuthProviderValue>(() => {
    return {
      authService,
      isAuthenticated: !!userId,
      hasAccessToken: authService.hasAccessToken,
      userId,
      userEmail,
      login: (credentials) =>
        authService.login(credentials).then((result) => {
          if (result === "success") {
            authModal?.closeModal();
            setUserId(authService.userId);
          } else if (result === "not-verified") {
            setUserId(authService.userId);
            cookies.set(REGISTRATION_EMAIL_COOKIE, credentials.login, {
              expires: 1,
              domain: `.${getDomain()}`,
            });
          }
          return result;
        }),
      logout: () => authService.logout().then(() => setUserId(undefined)),
      register: async (credentials) => {
        rudderstack?.track("User Credentials Entered", {
          optedIn: credentials.isOptIn,
        });
        const result = await authService.register(credentials);
        if (result === "success") {
          setUserId(authService.userId);
          cookies.set(REGISTRATION_EMAIL_COOKIE, credentials.email, {
            expires: 1,
            domain: `.${getDomain()}`,
          });
          authModal?.openModal("awaiting-verification-email");
        }
        return result;
      },
      resetPasswordRequest: (payload) =>
        authService.resetPasswordRequest(payload).then(() => {
          authModal?.openModal("reset-password-requested");
        }),
      resendVerificationEmail: () => authService.resendVerificationEmail(),
    };
  }, [authModal, authService, userEmail, userId]);

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
}

export { AuthProvider };
