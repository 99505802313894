import { PlayerPicks, PlayerPicksSchema } from "../shared/types/playerPicks";
import { FantasyPlayerPickRatesSchema } from "./schemas";
import { formatCircuit, formatTournament } from "./helpers/formatStrings";
import { blastDataBucketApi } from "../apiClients/blastDataBucketApi";

export class FantasyDataBucketApiClient {
  public constructor() {}

  queryKeys = {
    all: ["fantasyDataBucket"] as const,
    playerPicks: ({ circuitName, tournamentName }: { circuitName?: string; tournamentName?: string }) =>
      [...this.queryKeys.all, "playerPicks", circuitName, tournamentName] as const,
  };

  public getPlayerPicksForTournament = async ({
    circuitName,
    tournamentName,
  }: {
    circuitName: string;
    tournamentName: string;
  }): Promise<PlayerPicks | null> => {
    const formattedCircuit = formatCircuit(circuitName);
    const formattedTournamentName = formatTournament(tournamentName);

    const { data } = await blastDataBucketApi.get(
      `fantasy/statistics/${formattedCircuit || circuitName}/${
        formattedTournamentName || tournamentName
      }/pickRates.json`,
    );

    return PlayerPicksSchema.parse(data);
  };

  public getPlayerPickRates = async ({ seasonId, eventId }: { seasonId: string; eventId: string }) => {
    const { data } = await blastDataBucketApi.get(`fantasy/statistics/${seasonId}/${eventId}/pickRates.json`);
    return FantasyPlayerPickRatesSchema.parse(data);
  };
}
