import { GameId } from "@providers/GameIdProvider";

export const queryKeys = {
  all: ["sanity"] as const,
  settings: () => [queryKeys.all, "settings"] as const,
  homePage: (gameSection: string | null) => [queryKeys.all, "homePage", gameSection] as const,
  contentPage: ({ route }: { route: string }) => [queryKeys.all, "contentPage", route] as const,
  sidebar: () => [queryKeys.all, "sidebar"] as const,

  // Fantasy
  fantasy: () => [...queryKeys.all, "fantasy"] as const,
  event: ({ gameId, seasonId, eventId }: { gameId: GameId; seasonId?: string; eventId?: string }) =>
    [...queryKeys.fantasy(), gameId, seasonId, eventId, "event"] as const,
  seasons: ({ gameId }: { gameId: GameId }) => [...queryKeys.fantasy(), gameId, "seasons"] as const,
};
