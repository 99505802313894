import { TournamentTimelineSchema } from "../../shared/components/HeaderHomePage/components/TournamentsTimeline/schemas";
import { blastDataBucketApi } from "../apiClients/blastDataBucketApi";

export class TournamentsDataBucketApiClient {
  public constructor() {}

  queryKeys = {
    all: ["data-tournaments"] as const,
  };

  getTournamentsTimelineForHomePage = async () => {
    const dataUrl = `homepage/tournament-timeline.json`;

    const { data } = await blastDataBucketApi.get(dataUrl);
    if (!data) return undefined;

    return TournamentTimelineSchema.parse(data);
  };
}
