import classNames from "classnames";
import logo from "./assets/logo.svg";
import logoChristmas from "./assets/logo-christmas.svg";
import { useMemo } from "react";
import { DateTime } from "luxon";

export const Logo = ({ className }: { className?: string }) => {
  const logoSrc = useMemo(() => {
    const date = DateTime.utc();
    // use special logo for the month of December
    return date.month === 12 ? logoChristmas : logo;
  }, []);

  return <img className={classNames("size-full", className)} alt="Home" src={logoSrc} />;
};
