import { z } from "zod";

const BaseNavbarItemSchema = z.object({
  _type: z.literal("navbarItem"),
  _key: z.string(),
  title: z.string(),
  route: z.string().optional().nullable(),
  externalLink: z.string().optional(),
});

export type NavbarChildItem = z.infer<typeof BaseNavbarItemSchema>;

const TournamentNavbarItemSchema = z.object({
  _type: z.literal("navbarTournamentsItem"),
  _key: z.string(),
  title: z.string(),
  route: z.string(),
  children: z.array(BaseNavbarItemSchema).nullish(),
});

const NavbarToplevelItemSchema = z.discriminatedUnion("_type", [
  BaseNavbarItemSchema.extend({
    children: BaseNavbarItemSchema.array().nullish(),
  }),
  TournamentNavbarItemSchema,
]);

export const NavbarSchema = z.object({
  _type: z.literal("navbar"),
  items: z.array(NavbarToplevelItemSchema),
});

export type NavbarTopLevelItem =
  | (z.infer<typeof BaseNavbarItemSchema> & {
      children?: z.infer<typeof BaseNavbarItemSchema>[] | null;
    })
  | z.infer<typeof TournamentNavbarItemSchema>;
