import { ReactNode, createContext, useCallback, useRef, useState } from "react";
import { VideoPlayerModule } from "../../../remoteComponents/VideoPlayerModule";
import { useNavigate } from "react-router";
import { ReturnIcon } from "./components/ReturnIcon";
import { PauseIcon } from "./components/PauseIcon";
import { CloseIcon } from "./components/CloseIcon";
import { videoStorageService } from "../../services/videoStorageService";
import { PlayIcon } from "./components/PlayIcon";

interface PictureInPictureValue {
  enterPictureInPicture: ({
    src,
    targetTime,
    paused,
    returnUrl,
  }: {
    src: string;
    targetTime?: number;
    paused?: boolean;
    returnUrl: string;
  }) => void;
  exitPictureInPicture: () => void;
}

export const PictureInPictureContext = createContext<PictureInPictureValue>({
  enterPictureInPicture: () => {
    // do nothing
  },
  exitPictureInPicture: () => {
    // do nothing
  },
});

export const PictureInPictureProvider = ({ children }: { children: ReactNode }) => {
  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const [src, setSrc] = useState<string | undefined>(undefined);
  const [returnUrl, setReturnUrl] = useState<string | undefined>(undefined);
  const [paused, setPaused] = useState<boolean>(false);
  const [targetTime, setTargetTime] = useState<number | undefined>(undefined);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const navigate = useNavigate();

  const enterPictureInPicture = ({
    src,
    paused,
    targetTime,
    returnUrl,
  }: {
    src: string;
    paused?: boolean;
    targetTime?: number;
    returnUrl: string;
  }) => {
    setSrc(src);
    setPaused(Boolean(paused));
    setTargetTime(targetTime);
    setReturnUrl(returnUrl);
  };

  const exitPictureInPicture = () => {
    setSrc(undefined);
    setReturnUrl(undefined);
  };

  const seekToTargetTime = useCallback(() => {
    if (videoElementRef?.current && targetTime !== undefined) {
      videoElementRef.current.currentTime = targetTime;
    }
  }, [videoElementRef, targetTime]);

  const updateCurrentTime = useCallback(() => {
    if (videoElementRef?.current) setCurrentTime(videoElementRef?.current.currentTime);
  }, [videoElementRef]);

  return (
    <PictureInPictureContext.Provider
      value={{
        enterPictureInPicture,
        exitPictureInPicture,
      }}
    >
      {src && (
        <div className="fixed bottom-10 right-10 z-pictureInPictureVideoPlayer aspect-video w-[400px]">
          <VideoPlayerModule
            autoPlay={!paused}
            muted={videoStorageService.get().muted}
            onVolumeChange={() => {
              videoStorageService.set({ muted: Boolean(videoElementRef.current?.muted) });
            }}
            controls={false}
            src={src}
            ref={videoElementRef}
            onLoadedMetadata={seekToTargetTime}
            onTimeUpdate={updateCurrentTime}
          />
          <div className="absolute inset-0 z-10 flex items-center justify-center opacity-0 transition-opacity hover:opacity-100">
            {/* Return to live stream */}
            {returnUrl && (
              <button
                aria-label="Return to Live Stream"
                title="Return to Live Stream"
                className="m-1 cursor-pointer border-none bg-transparent"
                onClick={() =>
                  navigate(returnUrl, {
                    state: {
                      currentTime,
                    },
                  })
                }
              >
                <ReturnIcon />
              </button>
            )}
            {/* Pause */}
            <button
              aria-label={paused ? "Play" : "Pause"}
              title={paused ? "Play" : "Pause"}
              className="m-1 cursor-pointer border-none bg-transparent"
              onClick={() => {
                if (videoElementRef.current?.paused) {
                  videoElementRef.current?.play();
                  setPaused(false);
                } else {
                  videoElementRef.current?.pause();
                  setPaused(true);
                }
              }}
            >
              {paused ? <PlayIcon /> : <PauseIcon />}
            </button>
            {/* Close */}
            <button
              aria-label="Close"
              title="Close picture-in-picture"
              className="m-1 cursor-pointer border-none bg-transparent"
              onClick={() => setSrc(undefined)}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
      {children}
    </PictureInPictureContext.Provider>
  );
};
