import classNames from "classnames";
import { NavLink } from "./NavLink";
import { NavbarTopLevelItem } from "@data/sanity/getSettings/schemas/NavbarSchema";
import { useLocation } from "react-router";
import { ExternalArrow } from "./ExternalArrow";

export const NavTopLevelLink = ({ navbarItem, onClick }: { navbarItem: NavbarTopLevelItem; onClick?: () => void }) => {
  const location = useLocation();

  if (navbarItem._type === "navbarTournamentsItem") {
    // Handled separately in TournamentTimelineNav
    return null;
  }

  return (
    <NavLink
      navbarItem={navbarItem}
      onClick={onClick}
      className={classNames(
        "flex h-full items-center whitespace-nowrap border-y-2 border-transparent text-center text-neutral-0 transition-all font-style-navigation-desktop hover:text-neutral-10",
        {
          "border-b-yellow": navbarItem.route === location.pathname,
          "text-neutral-0 hover:text-neutral-10": navbarItem.route !== location.pathname,
        },
      )}
    >
      {navbarItem.title}
      {navbarItem.externalLink && <ExternalArrow className="ml-2" />}
    </NavLink>
  );
};
