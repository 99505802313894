import { camelCaseKeys } from "../shared/camelCaseKeys";
import { Rewatch, RewatchSchema } from "./schemas";
import { blastApi } from "../apiClients/blastApi";

export class VideoApiClient {
  constructor() {}

  getRewatchById = async (id: string): Promise<Rewatch | null> => {
    const { data } = await blastApi.get(`/v1/videos/rewatch/${id}`);
    return RewatchSchema.parse(camelCaseKeys(data));
  };
}
