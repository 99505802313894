import { ChatApiClient } from "../ChatApiClient";
import { PickemsApiClient } from "../PickemsApiClient";
import { PointsApiClient } from "../PointsApiClient";
import { TriggersApiClient } from "../TriggersApiClient";
import { AuthApiClient } from "../auth/AuthApiClient";
import { UsersApiClient } from "../UsersApiClient";
import { VideoApiClient } from "../VideoApiClient";
import { IngestionApiClient } from "../IngestionApiClient";
import { TournamentsDataBucketApiClient } from "../TournamentsDataBucketApiClient";
import { FantasyDataBucketApiClient } from "../FantasyDataBucketApiClient";
import { LeaderboardDataBucketApiClient } from "../LeaderboardDataBucketApiClient";
import { blastApi } from "../apiClients/blastApi";
import { AxiosInstance } from "axios";

export class ApiService {
  protected readonly blasttvApiAxiosInstance: AxiosInstance;

  public readonly auth: AuthApiClient;
  public readonly chat: ChatApiClient;
  public readonly fantasyDataBucket: FantasyDataBucketApiClient;
  public readonly ingestion: IngestionApiClient;
  public readonly pickems: PickemsApiClient;
  public readonly points: PointsApiClient;
  public readonly tournamentsDataBucket: TournamentsDataBucketApiClient;
  public readonly triggers: TriggersApiClient;
  public readonly users: UsersApiClient;
  public readonly video: VideoApiClient;
  public readonly leaderboardDataBucket: LeaderboardDataBucketApiClient;

  constructor() {
    this.blasttvApiAxiosInstance = blastApi;

    this.auth = new AuthApiClient();
    this.ingestion = new IngestionApiClient();
    this.pickems = new PickemsApiClient();
    this.points = new PointsApiClient();

    this.triggers = new TriggersApiClient();
    this.users = new UsersApiClient();
    this.video = new VideoApiClient();

    // data bucket apis
    this.chat = new ChatApiClient();
    this.fantasyDataBucket = new FantasyDataBucketApiClient();
    this.tournamentsDataBucket = new TournamentsDataBucketApiClient();
    this.leaderboardDataBucket = new LeaderboardDataBucketApiClient();
  }
}
